import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import some from "lodash/some"

import { Imgix } from "@elements"
import { htmlDecode } from "@utils"
import { useIncrement } from "@hooks"
import { Layout, SEO } from "@global"
import { Card } from "@elements"
import { CtaBillboard } from "@layouts"
import { AllCalculators } from "../Calculator"

export default ({ data }) => {
  const { posts, page, blogCategory } = data.wpgraphql

  const [category, setCategory] = useState("all")

  const [
    featuredPostCount,
    { inc: incFeaturedPosts, reset: resetFeaturedPosts },
  ] = useIncrement({
    maxValue: 24,
    minValue: 3,
    initial: 3,
    step: 6,
  })

  const [
    categoryPostCount,
    { inc: incCategoryPosts, reset: resetCategoryPosts },
  ] = useIncrement({
    maxValue: 24,
    minValue: 3,
    initial: 3,
    step: 6,
  })

  useEffect(() => {
    resetFeaturedPosts()
    resetCategoryPosts()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [category])

  return (
    <Layout>
      <SEO title="Resources" />

      <section className="module-hero flex flex-wrap null">
        <div className="w-full lg:w-7/12">
          <div className="px-4 pt-8 pb-10 pb-16 text-white lg:py-20 xl:pl-20 bg-gradient-brand min-h-hero lg:flex lg:justify-center lg:flex-col lg:items-start">
            <div className="sm:max-w-xl sm:mx-auto lg:max-w-md xl:max-w-2xl xl:mx-0">
              <h1 className="text-4xl font-medium leading-none sm:text-5xl lg:text-4xl xl:text-6xl font-display">
                {page.title}
              </h1>
              <p className="max-w-xs mt-5 sm:max-w-md xl:max-w-lg xl:text-lg">
                Helpful articles, financial tools and more
                <br /> are at your fingertips
              </p>
            </div>
          </div>
        </div>
        <div className="order-first w-full lg:order-none lg:w-5/12">
          <div className="relative h-48 overflow-hidden shadow-2xl sm:h-56 md:h-64 lg:h-full">
            <Imgix
              alt="Cropped shot of an attractive young woman hugging her grandmother"
              sizes="(min-width: 1024px) 41.6vw, 100vw"
              className="absolute inset-0 object-cover w-full h-full"
              src={`https://rum.imgix.net/wp-content/uploads/2020/06/03121210/RUMStock-1181416339-scaled.jpg`}
            />
          </div>
        </div>
      </section>

      <section className="resources-featured pt-16 bg-offwhite lg:pt-20">
        <h2
          className={`text-4xl xl:text-5xl text-center font-light leading-tight px-4`}
        >
          Featured Resources
        </h2>
        <div className="px-4 pb-16 mt-32 bg-white lg:pb-20 lg:mt-40">
          <div className="container mx-auto">
            <div className="flex flex-wrap transform -translate-y-24 lg:-mx-2 lg:justify-center">
              {posts.edges.slice(0, featuredPostCount).map(({ node }) => {
              
                var imageUrl = "";
              
                if (node.hasOwnProperty("featuredImage") && node.featuredImage && node.featuredImage.hasOwnProperty("sourceUrl")) {
                  imageUrl = node.featuredImage.sourceUrl;
                }
              
                return (
                  <div
                    key={node.id}
                    className="w-full max-w-lg mx-auto mt-6 lg:w-1/3 lg:mt-0 lg:mb-6 lg:px-2 lg:mx-0 lg:max-w-md"
                  >
                    <Card
                      title={node.title}
                      content={htmlDecode(node.excerpt)}
                      image={imageUrl}
                      linkUrl={node.uri}
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className="btn primary w-full sm:w-auto text-center"
              onClick={incFeaturedPosts}
            >
              View More
            </button>
          </div>
        </div>
      </section>

      <section className="resource-calculators">
        <div className="relative z-0 pt-16 pb-32 bg-white px-12">
          <h2
            className={`text-4xl xl:text-5xl text-center font-light leading-tight px-4`}
          >
            Calculators
          </h2>
          <p className="container max-w-4xl mt-4 mb-10 text-center">
            Give one of our easy-to-use calculators below a spin. Remember, your
            amounts are estimates only.
          </p>
        </div>

        <div className="relative transform -translate-y-32 ">
          <AllCalculators />
        </div>
      </section>

      <section className="resources-learn bg-offwhite">
        <h2
          className={`text-4xl xl:text-5xl text-center font-light leading-tight px-4`}
        >
          Learn at your own pace.
        </h2>
        <div className="px-4 mt-32 bg-white lg:mt-40">
          <div className="flex flex-wrap transform -translate-y-24 lg:-mx-2 lg:justify-center">
            <div className="w-full max-w-lg mx-auto mt-6 lg:w-1/3 lg:mt-0 lg:px-2 lg:mx-0 lg:max-w-md">
              <Card
                title="FAQs"
                content="You have questions, we have the answers. In fact, we have answers to some questions you might not even think of of yourself."
                alt="Man on the phone"
                image="https://rum.imgix.net/wp-content/uploads/2020/05/29004112/FAQs-resized.jpg"
                linkUrl="/resource-center/faqs"
              />
            </div>
            <div className="w-full max-w-lg mx-auto mt-6 lg:w-1/3 lg:mt-0 lg:px-2 lg:mx-0 lg:max-w-md">
              <Card
                title="Glossary"
                content="Use Royal United Mortgage's comprehensive mortgage glossary to get definitions of mortgage terms that may come up throughout your loan process."
                alt="Man on the phone"
                image="https://rum.imgix.net/wp-content/uploads/2020/05/26123721/resource-center-card.jpg"
                linkUrl="/mortgage-glossary"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Category Menu */}
      <div className="resources-blog-filters px-4 bg-white">
        <h2
          className={`text-4xl xl:text-5xl text-center font-light leading-tight px-4`}
        >
          Blogs, articles and more.
        </h2>
        <div className="container flex bg-white">
          <span className="relative flex items-center justify-center font-medium text-teal-500">
            FILTER:
          </span>
          <div className="flex w-full ml-8 overflow-x-scroll md:overflow-auto justify-start sm:justify-start md:justify-between lg:justify-around  ">
            <button
              className={`relative flex-shrink-0 flex items-center justify-center p-8 transition duration-150 cursor-pointer hover:text-teal-500 
            ${category === "all" ? `filter--active` : ``}
            `}
              onClick={() => setCategory("all")}
            >
              All
            </button>
            {blogCategory.children.nodes.map(cat => {
              return (
                <button
                  key={cat.id}
                  className={`relative flex-shrink-0 flex items-center justify-center p-8 transition duration-150 cursor-pointer hover:text-teal-500 
                ${category === cat.slug ? `filter--active` : ``}
                `}
                  onClick={() => setCategory(cat.slug)}
                >
                  {cat.name}
                </button>
              )
            })}
          </div>
        </div>
      </div>

      <section className="resources-blogs pt-1 bg-white lg:pt-2">
        <div className="px-4 pb-16 mt-32 lg:pb-20 bg-offwhite lg:mt-40">
          <div className="container mx-auto">
            <div className="flex flex-wrap transform -translate-y-24 lg:-mx-2 lg:justify-center">
              {posts.edges
                .filter(byCategory.bind(null, category))
                .slice(0, categoryPostCount)
                .map(({ node }) => {
                  
                  var imageUrl = "";
                  
                  if (node.hasOwnProperty("featuredImage") && node.featuredImage && node.featuredImage.hasOwnProperty("sourceUrl")) {
                    imageUrl = node.featuredImage.sourceUrl;
                  }
                
                  return (
                    <div
                      key={node.id}
                      className="w-full max-w-lg mx-auto mt-6 lg:w-1/3 lg:mt-0 lg:mb-6 lg:px-2 lg:mx-0 lg:max-w-md"
                    >
                      <Card
                        title={node.title}
                        content={htmlDecode(node.excerpt)}
                        image={imageUrl}
                        linkUrl={node.uri}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className="btn primary text-center w-full sm:w-auto"
              onClick={incCategoryPosts}
            >
              View More
            </button>
          </div>
        </div>
      </section>
      <CtaBillboard
        headline="Looking for more information?"
        content="Simply reach out and we can help."
        alignment="center"
        backgroundColor="gradient"
        callToActionGroup={{
          ctaLinkText: "Contact Us",
          ctaLinkUrl: "/contact-us/",
          ctaButtonType: "primary",
        }}
        ctaBillboardSecondCallToActionCallToActionGroup={{
          ctaLinkText: "",
          ctaLinkUrl: "",
          ctaButtonType: "",
        }}
      />
    </Layout>
  )
}

function byCategory(category, { node }) {
  if (category === "all") {
    return true
  }

  if (node.categories.nodes) {
    return some(node.categories.nodes, node => node.slug === category)
  }

  return false
}

export const ResourcesQuery = graphql`
  query ResourcesQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        slug
      }
      posts(first: 1000) {
        edges {
          node {
            id
            status
            uri
            slug
            title
            excerpt
            featuredImage {
              sourceUrl
            }
            categories(where: { shouldOutputInFlatList: true }) {
              nodes {
                id
                slug
              }
            }
          }
        }
      }
      blogCategory: category(id: "blog", idType: SLUG) {
        id
        name
        slug
        uri
        children {
          nodes {
            id
            name
            uri
            slug
          }
        }
      }
    }
  }
`
